<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-datepicker
              v-model="query.startDate"
              class="el-def"
              placeholder="조회기간(Fr)"
            />
            <label class="ml-25 mr-50">~</label>
            <b-form-datepicker
              v-model="query.endDate"
              class="el-def"
              placeholder="조회기간(To)"
            />
          </b-col>
          <b-col
            md="6"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="primary"
              class="btn-search"
              @click.prevent="fetchData()"
            >
              조회
            </b-button>
            <b-button
              variant="outline-dark"
              class="btn-search ml-1"
              @click.prevent="excelDownload()"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-50"
              />
              <span>엑셀 다운로드</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-card>
      <b-table-simple
        bordered
        responsive
        sticky-header
        style="max-height: 65vh;"
      >
        <colgroup>
          <col style="width: 8%">
          <col style="width: 12%">
          <col>
          <col style="width: 15%">
          <col style="width: 10%">
        </colgroup>
        <b-thead head-variant="light">
          <b-tr>
            <b-th>ID</b-th>
            <b-th>차수</b-th>
            <b-th>컨텐츠 제목</b-th>
            <b-th>진료과</b-th>
            <b-th>참여자수</b-th>
          </b-tr>
        </b-thead>
        <b-tbody v-if="wChoices.length > 0">
          <template v-for="m in wChoices">
            <b-tr
              v-for="(dept, deptIdx) in m.departments"
              :key="`poll-${m.poll.id}-dept-${dept.medicalDepartment}`"
            >
              <b-td
                v-if="deptIdx === 0"
                :rowspan="(m.departments.length + 1)"
              >
                {{ m.poll.id }}
              </b-td>
              <b-td
                v-if="deptIdx === 0"
                :rowspan="(m.departments.length + 1)"
              >
                {{ m.poll.degreeMonth }}-{{ m.poll.degree }}
              </b-td>
              <b-td
                v-if="deptIdx === 0"
                :rowspan="(m.departments.length + 1)"
              >
                {{ m.poll.pollTitle }}
              </b-td>
              <b-td>{{ dept.medicalDepartmentName }}</b-td>
              <b-td>{{ dept.sumCount | $numberFormatter }}</b-td>
            </b-tr>
            <b-tr
              v-if="m.departments.length > 0"
              :key="`dept-total-${m.poll.id}`"
            >
              <b-td>
                소계
              </b-td>
              <b-td>{{ m.departments.reduce((f, l) => f + l.sumCount, 0) | $numberFormatter }}</b-td>
            </b-tr>
          </template>
        </b-tbody>

        <b-tbody v-else>
          <b-tr>
            <b-td colspan="5">
              <div
                role="alert"
                aria-live="polite"
              >
                <div class="text-center my-2">
                  일치하는 데이터가 없습니다.
                </div>
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
    <b-pagination
      v-model="currPage"
      :total-rows="totalRecords"
      :per-page="pageCount"
      limit="10"
      align="center"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import axios from '@axios'
import { onMounted, ref, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BTableSimple, BThead, BTbody, BTr, BTh, BTd,
} from 'bootstrap-vue'
import ExcelJS from "exceljs"
import { saveAs } from "file-saver"

export default {
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
  },

  setup() {
    onMounted(() => {
      fetchData()
    })

    const toast = useToast()
    const pushToast = (variant, title) => {
      let icon

      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }

      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }

    const query = ref({
      startDate: dayjs().add(-30, 'day').format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD'),
    })

    const headers = ref([])
    const wChoices = ref([])
    const currPage = ref(1)
    const pageCount = ref(15)
    const totalRecords = ref(0)

    watch([currPage], () => {
      fetchData()
    })

    const fetchData = () => {
      axios.get('/fa/statistics/weekly-choice', {
        params: {
          startDate: query.value.startDate,
          endDate: query.value.endDate,
          pageNumber: currPage.value,
          pageCount: pageCount.value,
        },
      })
        .then(rs => {
          const { items, totalRecord } = rs.data
          wChoices.value = items
          totalRecords.value = totalRecord
        })
        .catch(() => {
          pushToast('danger', '데이터 조회 중 오류가 발생하였습니다.')
        })
    }

    const excelDownload = async () => {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet("sheet1")

      worksheet.columns = [
        { header: "ID", key: "id", width: 15 },
        { header: "차수", key: "degree", width: 20 },
        { header: "컨텐츠 제목", key: "title", width: 60 },
        { header: "진료과", key: "medicalDept", width: 20 },
        { header: "참여자수", key: "sumCount", width: 15 },
      ]

      const startRow = 2
      let endRow = startRow

      // eslint-disable-next-line array-callback-return
      wChoices.value.map(e => {
        e.departments.map(x => worksheet.addRow({
          id: e.poll.id,
          degree: `${e.poll.degreeMonth}-${e.poll.degree}`,
          title: e.poll.pollTitle,
          medicalDept: x.medicalDepartmentName,
          sumCount: x.sumCount,
        }))

        if (e.departments.length > 0) {
          worksheet.addRow({
            id: e.poll.id,
            degree: `${e.poll.degreeMonth}-${e.poll.degree}`,
            title: e.poll.pollTitle,
            medicalDept: '소계',
            sumCount: e.departments.reduce((f, l) => f + l.sumCount, 0),
          })

          worksheet.mergeCells(`A${endRow}:A${endRow + e.departments.length}`)
          worksheet.mergeCells(`B${endRow}:B${endRow + e.departments.length}`)
          worksheet.mergeCells(`C${endRow}:C${endRow + e.departments.length}`)

          endRow += e.departments.length
          endRow += 1
        }
      })

      worksheet.eachRow({ includeEmpty: true }, (row, number) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (number === 1) {
            // eslint-disable-next-line no-param-reassign
            cell.font = {
              bold: true,
            }

            // eslint-disable-next-line no-param-reassign
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'D3D3D3' },
            }
          }

          // eslint-disable-next-line no-param-reassign
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }

          // eslint-disable-next-line no-param-reassign
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'left',
          }
        })
      })

      const mimeType = {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }

      const buffer = await workbook.xlsx.writeBuffer()
      const blob = new Blob([buffer], mimeType)

      saveAs(blob, "WeeklyChoice.xlsx")
    }

    return {
      query,
      fetchData,
      headers,
      wChoices,
      currPage,
      pageCount,
      totalRecords,
      excelDownload,
    }
  },
}
</script>

<style scoped>
.table-bordered th, td {
  border: 1px solid #dee2e6;
}
</style>
